import React from "react";
import ImgRealState from "../assets/img/real-estate-02.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Funtionality() {
  AOS.init();
  return (
    <section 
    data-aos="flip-down" 
    data-aos-duration="1000" 
    className="flex lg:flex-row justify-center items-center flex-col flex-col-reverse mx-10 mb-10 ">
      <div className="lg:w-[30%] md:w-[100%] lg:mt-24 sm:my-10">
        <div className="mb-5">
          <h2 className="font-bold text-xl">JDYC</h2>
          <h3 className="font-bold text-xl">Negocios Inmobiliarios</h3>
          <p className="font-bold text-xl">Funcionalidad</p>
          <p className="font-bold text-xl">Seguridad</p>
        </div>

        <p className="font-light" >
          {" "}
          Tu socio de confianza en el mundo inmobiliario. Nos destacamos por ofrecer un servicio seguro y confiable, respaldado por nuestro compromiso con la integridad y la transparencia en cada transacción.{" "}
        </p>

        <div>
          <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
            <li className="flex gap-x-3 items-center">
              <span className="bg-black w-8 h-8 rounded-full flex items-center justify-center " >
              <svg
                className="h-6 w-5 flex-none text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
             

              </svg>
              </span>
              Servicio confiable
            </li>
            <li className="flex gap-x-3 items-center">
            <span className="bg-black w-8 h-8 rounded-full flex items-center justify-center " >
              <svg
                className="h-6 w-5 flex-none text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
             

              </svg>
              </span>
              Protección de datos
            </li>
            <li className="flex gap-x-3 items-center">
            <span className="bg-black w-8 h-8 rounded-full flex items-center justify-center " >
              <svg
                className="h-6 w-5 flex-none text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
             

              </svg>
              </span>
              Validación rigurosa
            </li>
            <li className="flex gap-x-3 items-center">
            <span className="bg-black w-8 h-8 rounded-full flex items-center justify-center " >
              <svg
                className="h-6 w-5 flex-none text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
             

              </svg>
              </span>
              Transparencia total
            </li>
          </ul>
        </div>
        <div className="my-8 inline-flex items-center bg-cyan-100 p-4 border-l-8 border-cyan-300 text-xs text-justify font-ligth text-cyan-900 ring-1 ring-inset ring-blue-700/10"  >
          <p>
          Con un enfoque centrado en el cliente, garantizamos la protección de tus datos y la verificación exhaustiva de cada operación. Nuestra dedicación a la seguridad y la excelencia nos distingue en el mercado, brindándote la tranquilidad que necesitas al comprar o vender una propiedad.
          </p>
        </div>
      </div>

      <div className="lg:w-[40%] md:w-[100%]">
        <img
          src={ImgRealState}
          alt="img-real-state"
          className="w-full fit-content"
        />
      </div>
    </section>
  );
}

export default Funtionality;
