import './App.css';
import Layout from './components/layout/Layout';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './components/layout/Landing';
import Properties from './components/layout/Properties';
import NotFound404 from './components/layout/404';
import PropertyDetail from './components/layout/PropertyDetail';
import Admin from './components/layout/Admin';
import Evaluated from './components/layout/Evaluated';
import Privacity from './components/layout/Privacity';
import Conditions from './components/layout/Conditions';
import SiteMap from './components/layout/SiteMap';
import LayoutAdmin from './components/layout/LayoutAdmin';
import Login from './components/layout/Login';
import RealStateAdmin from './components/layout/RealStateAdmin';


function App() {
  return (
    // <BrowserRouter>
    //   <Layout>
    //     <SpeedInsights/>
    //     <Analytics/>
    //     <Routes>
    //       <Route path='/' element={<Landing />} />
    //       <Route path='/propiedades' element={<Properties />} />
    //       <Route path='/propiedad/:id' element={<PropertyDetail />} />
    //       <Route path='/administracion' element={<Admin />} />
    //       <Route path='/tasaciones' element={<Evaluated />} />
    //       <Route path='*' element={<NotFound404 />} />
    //       <Route path='/privacity' element={<Privacity />} />
    //       <Route path='/conditions' element={<Conditions />} />
    //       <Route path='/site-map' element={<SiteMap />} />
    //     </Routes>
    //   </Layout>
    // </BrowserRouter>
    <BrowserRouter>

    <Routes>
      {/* Rutas que utilizan el Layout principal */}
      <Route element={<Layout />}>
        <Route path='/' element={<Landing />} />
        <Route path='/propiedades' element={<Properties />} />
        <Route path='/propiedad/:id' element={<PropertyDetail />} />
        <Route path='/administracion' element={<Admin />} />
        <Route path='/tasaciones' element={<Evaluated />} />
        <Route path='/privacity' element={<Privacity />} />
        <Route path='/conditions' element={<Conditions />} />
        <Route path='/site-map' element={<SiteMap />} />
        <Route path='*' element={<NotFound404 />} />
      </Route>

      {/* Rutas que utilizan el Layout de administración */}
      <Route element={<LayoutAdmin />}>
        <Route path='/login' element={<Login />} />
        <Route path='/admin/dashboard' element={<RealStateAdmin />} />
      </Route>
    </Routes>

    {/* Global components outside routes */}
    <SpeedInsights />
    <Analytics />

  </BrowserRouter>
  );
}

export default App;
