import React from "react";
import { PiBathtubLight, PiBedLight, PiRulerLight } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import { CiMail, CiMobile3, CiUser } from "react-icons/ci";
import { dataProps } from "../../data/dataProperties";
import { MdArrowBackIos } from "react-icons/md";
function PropertyDetail() {
  const { id } = useParams();
  const item = dataProps.find((item) => item.id === parseInt(id));



  return (
    <main className=" flex flex-col justify-center items-center ">
      <div className="lg:w-[80%] w-full flex justify-end mb-4 items-center" >
      <span className="inline-flex items-center border px-2 rounded-md  bg-[#EB6E3C]" >
      <MdArrowBackIos className="text-gray-50" />
        <Link className="px-4 py-1 text-gray-50 " to={'/propiedades'} >
          Atras
        </Link>
        </span>
      </div>
      <div className="flex flex-col lg:w-[80%] w-full lg:px-0 px-5 border-b pb-2">

        <h2 className="font-bold text-3xl">{item.titulo}</h2>
        <p className="font-hairline text-xs text-gray-500 ">
          {`${item.direccionCalle} #${item.direccionNumero} - ${item.provincia ? item.provincia : ""} - ${item.ciudad ? item.ciudad : ""}`}
        </p>
      </div>

      <div className=" lg:w-[80%] lg:p-0  w-full lg:flex md:flex-wrap ">
        <div className=" lg:w-8/12 w-[100%] p-2 ">
          <img className="w-full   " src={item.img01} alt="propiedad" />
        </div>

        <div className="lg:w-4/12 flex lg:flex-col p-2 gap-2   ">
          <img className="lg:w-full w-[49%] " src={item.img02} alt="propiedad" />
          <img className="lg:w-full  w-[49%]" src={item.img03} alt="propiedad" />
        </div>
      </div>

      <div className="lg:w-[80%] lg:p-2 p-4 w-full flex flex-wrap wrap-reverse  ">
        <div className="lg:w-8/12 w-[100%] p-2">
          <div className=" w-full">


            <div className="flex border-t border-b items-center w-full justify-between  ">
              <div className="flex gap-1 items-center">
                <PiBedLight className="text-orange-500" />
                <p className="text-sm">{item.habitaciones}Hb</p>
              </div>

              <span className="border-l m-2 h-6"></span>

              <div className="flex gap-1 items-center ">
                <PiBathtubLight className="text-orange-500" />
                <p className="text-sm">{item.banos}Bñ</p>
              </div>

              <span className="border-l m-2 h-6"></span>

              <div className="flex gap-1 items-center">
                <PiRulerLight className="text-orange-500" />
                <p className="text-sm">
                  {item.metrosT}m
                  <span className="text-xs align-top text-gray-500 ">2</span>
                </p>
              </div>
            </div>
          </div>
          <div className="my-2">
            <h2 className="font-bold">
              {`${item.descripcionCorta ? item.descripcionCorta : ""}`}
            </h2>
            <p className="font-thin text-justify">
              {`${item.descripcionLarga ? item.descripcionLarga : ""}`}
            </p>
          </div>
        </div>

        <div className="lg:w-4/12 flex w-[100%] flex-col items-center   ">
          <a target="_blank" rel="noreferrer" href={`https://wa.me/${item.telefono}/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20${item.titulo}`} className=" bg-gray-900 w-60 py-2 px-4 mb-4 flex items-center justify-center text-gray-50 ">Contactar</a>

          <div className=" w-full  p-2 flex flex-col gap-2 " >
            <div className="flex gap-1 items-center ">
              <CiUser className="text-orange-500" />
              <p className="text-sm">{item.contacto}</p>
            </div>
            <div className="flex gap-1 items-center ">
              <CiMobile3 className="text-orange-500" />
              <p className="text-sm">{item.telefono}</p>
            </div>
            <div className="flex gap-1 items-center ">
              <CiMail className="text-orange-500" />
              <p className="text-sm">{item.email}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PropertyDetail;
