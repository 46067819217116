
import p01a from '../assets/properties/lotes/prop01a.jpg'
import p01b from '../assets/properties/lotes/prop01b.jpg'
import p01c from '../assets/properties/lotes/prop01c.jpg'
import p02a from '../assets/properties/lotes/prop02a.jpg'
import p02b from '../assets/properties/lotes/prop02b.jpg'
import p02c from '../assets/properties/lotes/prop02c.jpg'
import p02d from '../assets/properties/lotes/prop02d.jpg'
import p02e from '../assets/properties/lotes/prop02e.jpg'
import p02f from '../assets/properties/lotes/prop02f.jpg'
import p02g from '../assets/properties/lotes/prop02g.jpg'
import p02h from '../assets/properties/lotes/prop02h.jpeg'
import p02i from '../assets/properties/lotes/prop02i.jpeg'
import p03a from '../assets/properties/lotes/prop03a.jpg'
import p03b from '../assets/properties/lotes/prop03b.jpg'
import p03c from '../assets/properties/lotes/prop03c.jpg'
import p03d from '../assets/properties/lotes/prop03d.jpg'
import p03e from '../assets/properties/lotes/prop03e.jpg'
import p04a from '../assets/properties/lotes/prop04a.jpeg'
import p04b from '../assets/properties/lotes/prop04b.jpeg'
import p04c from '../assets/properties/lotes/prop04c.jpeg'
import p04d from '../assets/properties/lotes/prop04d.jpeg'
import p05a from '../assets/properties/lotes/prop05a.jpg'
import p05b from '../assets/properties/lotes/prop05b.jpg'
import p05c from '../assets/properties/lotes/prop05c.jpg'
import p06a from '../assets/properties/lotes/prop06a.jpeg'
import p06b from '../assets/properties/lotes/prop06b.jpeg'
import p06c from '../assets/properties/lotes/prop06c.jpg'
import p06d from '../assets/properties/lotes/prop06d.jpg'
import p06e from '../assets/properties/lotes/prop06e.jpeg'
import p07a from '../assets/properties/lotes/prop07a.jpg'
import p07b from '../assets/properties/lotes/prop07b.png'
import p07c from '../assets/properties/lotes/prop07c.png'
import p08a from '../assets/properties/casaAltaGracia0108.jpeg'








export const dataProps = [
    {
        id:1,
        titulo: "Lote en Alta Gracia",
        direccionCalle:"Calle 24 de septiembre",
        direccionNumero:1550,
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"Alta Gracia",
        barrio:"",
        habitaciones:1,
        banos: 1,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:878,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"15.000",
        financiacion:"con financiación",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547565707",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p01a,
        img02:p01b,
        img03:p01c,
        galeria :{
            img01:p01a,
            img02:p01b,
            img03:p01c,
            img04:p01a,
            img05:p01b,
            img06:p01c,
            img07:p01a,
            img08:p01b,
            img09:p01c,
            img10:p01a,
        },
        video:null
      },
      {
        id:2,
        titulo: "Lotes en Jose de la Quintana",
        direccionCalle:"Jose de la Quintana",
        direccionNumero:"-",
        direccionReferencia: "_",
        provincia: "Córdoba",
        ciudad:"",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:253,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Descubra una oportunidad única para adquirir su propio espacio en José de la Quintana, donde ofrecemos una variedad de lotes disponibles para venta. Con superficies que varían desde los 253 metros cuadrados hasta los 711 metros cuadrados, encontrará el espacio perfecto para sus necesidades, ya sea para construir la casa de sus sueños o invertir en un proyecto inmobiliario.\n\nPrecios y Financiación:\n\n- Precios: Los lotes están disponibles con un rango de precios desde USD 3,900 hasta USD 8,177, asegurando opciones para diferentes presupuestos.\n\n- Formas de Pago:\n  - Contado: Aproveche un descuento especial al pagar de contado.\n  - Financiación: Ofrecemos planes de financiamiento accesibles con entregas iniciales desde USD 2,195 y el saldo restante en 12 o 18 cuotas, pagaderas en USD o en pesos ajustables al índice de construcción ICC.\n\nNo pierda la oportunidad de invertir en una propiedad en José de la Quintana, una zona en crecimiento con un gran potencial de valorización. Contáctenos hoy mismo para obtener más información y asegurar su lote ideal.",
        precio:"3900",
        financiacion:"Desde USD3900",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p02a,
        img02:p02b,
        img03:p02c,
        galeria :{
            img01:p02a,
            img02:p02b,
            img03:p02c,
            img04:p02d,
            img05:p02e,
            img06:p02f,
            img07:p02g,
            img08:p02h,
            img09:p02i,
        },
        video:null
      },
      {
        id:3,
        titulo: "Lote en Altos de Liniers ",
        direccionCalle:"Altos de Liniers",
        direccionNumero:"",
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"Alta Gracia",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:878,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"Consultar",
        financiacion:"3 cuota de 6000",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p03a,
        img02:p03b,
        img03:p03c,
        galeria :{
            img01:p03a,
            img02:p03b,
            img03:p03c,
            img04:p03d,
            img05:p03e,
        },
        video:null
      },
      {
        id:4,
        titulo: "Lotes continuos Potrero de Garay",
        direccionCalle:"Potrero de Garay",
        direccionNumero:"",
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"potrero de Garay",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:300,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"Consultar",
        financiacion:"3 cuota de 6000",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p04a,
        img02:p04b,
        img03:p04c,
        galeria :{
            img01:p04a,
            img02:p04b,
            img03:p04c,
            img04:p04d,
        },
        video:null
      },
      {
        id:5,
        titulo: "Lotes en villa Juana",
        direccionCalle:"Villa Juana",
        direccionNumero:"-",
        direccionReferencia: "_",
        provincia: "Córdoba",
        ciudad:"a minutos de Alta Gracia",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:253,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Descubra una oportunidad única para adquirir su propio espacio en José de la Quintana, donde ofrecemos una variedad de lotes disponibles para venta. Con superficies que varían desde los 253 metros cuadrados hasta los 711 metros cuadrados, encontrará el espacio perfecto para sus necesidades, ya sea para construir la casa de sus sueños o invertir en un proyecto inmobiliario.\n\nPrecios y Financiación:\n\n- Precios: Los lotes están disponibles con un rango de precios desde USD 3,900 hasta USD 8,177, asegurando opciones para diferentes presupuestos.\n\n- Formas de Pago:\n  - Contado: Aproveche un descuento especial al pagar de contado.\n  - Financiación: Ofrecemos planes de financiamiento accesibles con entregas iniciales desde USD 2,195 y el saldo restante en 12 o 18 cuotas, pagaderas en USD o en pesos ajustables al índice de construcción ICC.\n\nNo pierda la oportunidad de invertir en una propiedad en José de la Quintana, una zona en crecimiento con un gran potencial de valorización. Contáctenos hoy mismo para obtener más información y asegurar su lote ideal.",
        precio:"Consultar",
        financiacion:"Desde USD3900",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p05a,
        img02:p05b,
        img03:p05c,
        galeria :{
            img01:p05a,
            img02:p05b,
            img03:p05c,

        },
        video:null
      },
      {
        id:6,
        titulo: "Lote en Pampa Alta",
        direccionCalle:"Pampa Alta",
        direccionNumero:"",
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:420,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"6.000",
        financiacion:"3 cuota de 6000",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p08a,
        img02:p06d,
        img03:p06c,
        galeria :{
            img01:p06a,
            img02:p06b,
            img03:p06c,
            img04:p06d,
            img05:p06e,
        },
        video:null
      },
      {
        id:7,
        titulo: "Lote en La Pradera Kanmar ",
        direccionCalle:"Anisacate",
        direccionNumero:"",
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"Anisacate",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:420,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"6.900",
        financiacion:"3 cuota de 6000",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p07a,
        img02:p07b,
        img03:p07c,
        galeria :{
            img01:p07a,
            img02:p07b,
            img03:p07c,

        },
        video:null
      },
          {
        id:8,
        titulo: "Casa en alta gracia",
        direccionCalle:"Belisario Roldan",
        direccionNumero:"",
        direccionReferencia: "Paralelo a ruta interna Alta Gracia - La Bolsa",
        provincia: "Córdoba",
        ciudad:"Anisacate",
        barrio:"",
        habitaciones:0,
        banos: 0,
        servicios:{
            luz: true,
            agua: true,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        ambientes: null,
        metrosT:420,
        metrosC:null,
        descripcionCorta:"",
        descripcionLarga: "Esta encantadora propiedad con forma de “L” ofrece una oportunidad única para inversionistas y compradores. En el terreno se encuentra una edificación antigua, que solía ser la casa del casero, con cocina, dormitorio y baño. Aunque requiere renovaciones, esta estructura proporciona una base sólida para cualquier proyecto de restauración.\n\nAdemás, el lote cuenta con cimientos ya iniciados, lo que facilita la continuación de la construcción de una nueva vivienda o ampliación según sus necesidades. La propiedad está completamente escriturada, asegurando una transacción legal y sin complicaciones.\n\nCon su ubicación estratégica y el potencial para el desarrollo, este lote es ideal para aquellos que buscan construir la casa de sus sueños o invertir en un proyecto inmobiliario. No pierda la oportunidad de transformar este espacio en un hogar o negocio próspero.",
        precio:"6.900",
        financiacion:"3 cuota de 6000",
        moneda:"USD",
        tipo:"lote",
        operacion:"venta",
        telefono:"+5493547546784",
        email:"inmobiliariajdyc@gmail.com",
        contacto:"Administración JDYC",
        img01:p07a,
        img02:p07b,
        img03:p07c,
        galeria :{
            img01:p07a,
            img02:p07b,
            img03:p07c,

        },
        video:null
      },
]
