import React from "react";
import { SlSocialFacebook, SlSocialInstagram } from "react-icons/sl";
import { PiTiktokLogoLight } from "react-icons/pi";
import LogoJDYC from "../assets/horizontal/logoNyN.png";
import { FaMailBulk, FaPhoneAlt } from "react-icons/fa";
import { dataProps } from "../data/dataProperties";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-gray-950 py-20  ">
      <div className="border-t border-t-0.5 border-b border-b-0.5 border-gray-500 lg:mx-20  justify-center  flex flex-wrap lg:gap-0 gap-10 py-10 mb-10">
        <div className="flex flex-col justify-center lg:items-start items-center gap-2 lg:w-[30%] w-full  h-full">
          <div>
            <img className="w-[16rem] " src={LogoJDYC} alt="Logo JD Yuncai" />
          </div>

          <div className="flex items-center gap-2">
            <div className=" w-12 h-12 flex justify-center items-center bg-gray-100 rounded-full ">
              <FaPhoneAlt />
            </div>
            <a className="text-gray-100" href="tel:+5493547500256" >+54 9 354 7500256</a>
          </div>

          <div className="flex items-center gap-2 justify-center ">
            <div className=" w-12 h-12 flex justify-center items-center bg-gray-100 rounded-full ">
              <FaMailBulk />
            </div>
            <a className="text-gray-100" href="mailto:inmobiliariajdyc@gmail.com" >inmobiliariajdyc@gmail.com</a>
          </div>
        </div>

        <div className="flex flex-col justify-center lg:items-start items-center  gap-2 lg:w-[30%] w-full h-full">
          <div>
            <h2 className="text-gray-100 text-2xl">Inmobiliaria</h2>
          </div>

          <div className="">
            {/* <p className="text-gray-100">Casas</p>
            <p className="text-gray-100">Lotes</p>
            <p className="text-gray-100">Departamentos</p>
            <p className="text-gray-100">Locales</p>
            <p className="text-gray-100">Oficinas</p> */}
            <p className="text-gray-100">Proyectos</p>
            <p className="text-gray-100">Politicas de privacidad</p>
            <p className="text-gray-100">Terminos y condiciones</p>
            <p className="text-gray-100">Mapa del sitio</p>
          </div>
        </div>

        <div className="flex flex-col justify-center lg:items-start items-center  gap-2 lg:w-[40%] w-full ">
          <h2 className="text-gray-100 text-2xl">Propiedades</h2>

          <div className="flex flex-wrap gap-4 lg:justify-start justify-center ">

            {
              dataProps.slice(0, 6).map((item) => {
                return (
                  <Link key={item.id} to={`/propiedad/${item.id}`} >
                    <img className="w-[10rem] h-[5rem] object-fit " src={item.img01} alt="propiedad" />
                  </Link>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="flex container-lg justify-evenly items-center  lg:flex-row md:flex-col-reverse flex-wrap md:gap-2 ">
        <p className="text-gray-100 font-extralight text-center ">
          Copyright 2024 © JDYC - Negocios Inmobiliarios | Desarrollador por{" "}
          <a target="blank" className="text-teal-400 " href="https://asweb.com.ar">
            ArtSet Web
          </a>{" "}
        </p>

        <div className="flex items-center gap-4">
          <p className="text-gray-100 font-extralight">Seguínos en</p>

          <div className="flex gap-2">
            <a href="https://www.instagram.com/jdyc_negociosinmobiliarios" target="blank" className="bg-gray-800 w-12 h-12 p-1 flex justify-center items-center rounded-full ">
              <SlSocialInstagram className="text-gray-100" />
            </a>

            <a href="https://www.facebook.com/profile.php?id=61557982785554" target="blank" className="bg-gray-800 w-12 h-12 p-1 flex justify-center items-center rounded-full ">
              <SlSocialFacebook className="text-gray-100" />
            </a>

            <a href="https://www.tiktok.com/@jdycnegociosinmobiliario?lang=es" target="blank" className="bg-gray-800 w-12 h-12 p-1 flex justify-center items-center rounded-full ">
              <PiTiktokLogoLight className="text-gray-100" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
