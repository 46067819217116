import React, { useState } from "react";
import PremiunCard from "./pureComponentes/PremiunCard";
import { Link } from "react-router-dom";
import { dataProps } from "../data/dataProperties";
// eslint-disable-next-line
import { supabase } from "../supabaseClient";


function ExplorePremiun() {

// eslint-disable-next-line
const [properties, setProperties] = useState([dataProps])



  return (
    <section className="container-xl mt-10 ">

      <div className="flex lg:flex-row sm:flex-col justify-evenly lg:gap-[30vw] w-full lg:container-md lg:px-44 lg:justify-between  ">
        <h2 className="">
          Explora nuestras<span className="block">propiedades</span>
        </h2>


        <Link to={'/propiedades'} className=" bg-gray-900 w-40 py-2 px-4 flex items-center justify-center ">
          <p className="text-gray-50 text-center ">Propiedades</p>
          </Link>

      </div>

      <div className="container-md flex flex-wrap justify-center items-center lg:gap-14 gap-8 p-10 ">
        {
          dataProps?.map((item, index) => (
            <PremiunCard key={index} item={item} />
          ))
        }
      </div>
    </section>
  );
}

export default ExplorePremiun;
