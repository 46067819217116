import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient'; // Asegúrate de tener esta configuración de cliente
import { v4 as uuidv4 } from 'uuid'; // Para generar IDs únicos para las imágenes
import { toast, ToastContainer } from 'react-toastify';

const AddProperties = () => {
    // eslint-disable-next-line
    const [img01, setImg01] = useState(null);
    const [img02, setImg02] = useState(null);
    const [img03, setImg03] = useState(null);
    const provinces = [
        "Buenos Aires", "Córdoba", "Santa Fe", "Mendoza", "Tucumán", "Salta", "Jujuy",
        "San Luis", "San Juan", "La Pampa", "Neuquén", "Río Negro", "Chubut", "Santa Cruz",
        "Tierra del Fuego", "Misiones", "Corrientes", "Entre Ríos", "Formosa", "Chaco",
        "La Rioja", "Catamarca", "Santiago del Estero"
    ];
    const [formData, setFormData] = useState({
        titulo: "",
        direccion_calle: "",
        direccion_numero: "",
        direccion_referencia: "",
        provincia: "",
        ciudad: "",
        barrio: "",
        habitaciones: 1,
        banos: 1,
        servicios: {
            luz: false,
            agua: false,
            gas: false,
            gasNatural: false,
            calefaccion: false,
            piscina: false,
            gimnasio: false,
            salon: false,
            comedor: false,
            cocina: true,
            banos: true,
            aireAcondicionado: false,
            escalera: false,
            patio: false,
            jardin: false,
            escritura: true,
        },
        metros_t: 0,
        metros_c: null,
        descripcion_corta: "",
        descripcion_larga: "",
        precio: "",
        financiacion: "",
        moneda: "USD",
        tipo: "",
        operacion: "venta",
        telefono: "",
        email: "",
        contacto: "",
        img01: null,
        img02: null,
        img03: null,
        video: null,
    });

    const [userId, setUserId] = useState('')
    // eslint-disable-next-line
    const [media, setMedia] = useState([])

    const getUser = async () => {

        try {
            const { data: { user } } = await supabase.auth.getUser()
            if (user !== null) {
                setUserId(user.id);
                console.log(user)
            } else {
                console.log('No hay usuario logueado');
                setUserId('')
            }
        } catch (error) {
            console.log(error)
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
// eslint-disable-next-line
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0],
        });
    };

    useEffect(() => {
        getUser()
    }, [userId])

    const uploadImage = async (e) => {


        let file = e.target.files[0]
        const fileName = `${userId}/${uuidv4()}_${file.name}`; // Nombre único

        const { data, error } = await supabase.storage.from('jdyc').upload(fileName, file);

        if (data) {
            toast.success('Imágen subida con éxito', 'success');
            console.log(data.fullPath)
            return data.fullPath;
        } else {
            console.error('Error al subir la imagen:', error.message);
            toast.error('Error al subir la imagen')
            throw new Error('Error al subir la imagen');
        }
    };
// eslint-disable-next-line
    async function uploadPropsImage(e) {
        let file = e.target.files[0]

        const { data, error } = await supabase
            .storage
            .from('jdyc')
            .upload(userId + "/" + uuidv4(), file)

        if (data) {
            toast.success('Imágen subida con éxito', 'success');
            getMedia()
        } else {
            toast.error('Error al subir la imágen', 'error');
            console.log(error)
        }

    }

    async function getMedia() {

        const { data, error } = await supabase
            .storage
            .from('jdyc')
            .list(userId + "/", {
                limit: 100,
                offset: 0,
                sortBy: {
                    column: 'created_at',
                    order: 'desc',
                }
            })

        if (data) {
            setMedia(data)
        } else {
            console.log(71, error)
        }

    }


    // Manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Subir las imágenes
            const img01URL = img01 ? await uploadImage(img01) : null;
            const img02URL = img02 ? await uploadImage(img02) : null;
            const img03URL = img03 ? await uploadImage(img03) : null;

            // Si alguna imagen falla, lanzar un error
            if (!img01URL || !img02URL || !img03URL) {
                throw new Error('Error al subir las imágenes');
            }

            // Promesa para insertar la propiedad en la base de datos
            const insertPromise = supabase.from('properties').insert([
                {
                    ...formData,
                    img01: img01URL,
                    img02: img02URL,
                    img03: img03URL,
                },
            ]);

            // Mostrar el toast mientras se realiza la inserción
            toast.promise(insertPromise, {
                pending: 'Agregando propiedad...',
                success: 'Propiedad agregada exitosamente!',
                error: 'Error al agregar la propiedad. Inténtalo de nuevo.',
            });
// eslint-disable-next-line
            const { data, error } = await insertPromise;

            if (error) {
                console.error('Error al insertar la propiedad:', error.message);
            } else {
                console.log('Propiedad agregada con éxito');
                // Restablecer el formulario aquí si es necesario
            }
        } catch (error) {
            console.error('Error al procesar:', error.message);
            toast.error('Error al agregar la propiedad. Inténtalo de nuevo.');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Agregar Nueva Propiedad</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                {/* Campo de título */}
                <div>
                    <label className="block mb-2 font-semibold">Título</label>
                    <input
                        type="text"
                        name="titulo"
                        value={formData.titulo}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded"
                        required
                    />
                </div>

                {/* Dirección */}
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Calle</label>
                        <input
                            type="text"
                            name="direccion_calle"
                            value={formData.direccion_calle}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Número</label>
                        <input
                            type="number"
                            name="direccion_numero"
                            value={formData.direccion_numero}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Referencia</label>
                    <input
                        type="text"
                        name="direccion_referencia"
                        value={formData.direccion_referencia}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded"
                    />
                </div>

                {/* Provincia */}
                <div>
                    <label className="block mb-2 font-semibold">Provincia</label>
                    <select
                        name="provincia"
                        value={formData.provincia}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded"
                        required
                    >
                        <option value="">Selecciona una provincia</option>
                        {provinces.map((province) => (
                            <option key={province} value={province}>
                                {province}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Ciudad y Barrio */}
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Ciudad</label>
                        <input
                            type="text"
                            name="ciudad"
                            value={formData.ciudad}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Barrio</label>
                        <input
                            type="text"
                            name="barrio"
                            value={formData.barrio}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                        />
                    </div>
                </div>

                {/* Ambientes, Metros Techados, Metros Cuadrados */}
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Ambientes</label>
                        <input
                            type="number"
                            name="ambientes"
                            value={formData.ambientes}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Metros Techados</label>
                        <input
                            type="number"
                            name="metros_t"
                            value={formData.metros_t}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Metros Cuadrados</label>
                        <input
                            type="number"
                            name="metros_c"
                            value={formData.metros_c}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                        />
                    </div>
                </div>

                {/* Descripción corta y larga */}
                <div>
                    <label className="block mb-2 font-semibold">Descripción Corta</label>
                    <input
                        type="text"
                        name="descripcion_corta"
                        value={formData.descripcion_corta}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded"
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Descripción Larga</label>
                    <textarea
                        name="descripcion_larga"
                        value={formData.descripcion_larga}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded"
                        rows={5}
                        required
                    />
                </div>

                {/* Financiación, Precio y Moneda */}
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Financiación</label>
                        <input
                            type="text"
                            name="financiacion"
                            value={formData.financiacion}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Precio</label>
                        <input
                            type="text"
                            name="precio"
                            value={formData.precio}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Moneda</label>
                        <select
                            name="moneda"
                            value={formData.moneda}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        >
                            <option value="USD">$USD</option>
                            <option value="ARS">$ARS</option>
                        </select>
                    </div>
                </div>

                {/* Tipo y Operación */}
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Tipo de Propiedad</label>
                        <select
                            name="tipo"
                            value={formData.tipo}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        >
                            <option value="Lote">Lote</option>
                            <option value="Casa">Casa</option>
                            <option value="Depto">Depto</option>
                            <option value="Local">Local</option>
                            <option value="Turismo">Turismo</option>
                            <option value="Oficinas">Oficinas</option>
                            <option value="Industrial">Industrial</option>
                        </select>
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Operación</label>
                        <select
                            name="operacion"
                            value={formData.operacion}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        >
                            <option value="Venta">Venta</option>
                            <option value="Alquiler">Alquiler</option>
                        </select>
                    </div>
                </div>

                {/* Email y contacto */}
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 font-semibold">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Contacto</label>
                        <input
                            type="text"
                            name="contacto"
                            value={formData.contacto}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Telefono</label>
                        <input
                            type="number"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 border rounded"
                            required
                        />
                    </div>
                </div>

                {/* Imágenes */}
                <div>
                    <label className="block mb-2 font-semibold">Imagen Principal 1</label>
                    <input
                        type="file"
                        name="img01"
                        accept="image/*"
                        //  onChange={(e) => setFormData({ ...formData, img01: e.target.files[0] })}
                        onChange={(e) => uploadImage(e)}
                        className="w-full px-4 py-2 border rounded"
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Imagen Principal 2</label>
                    <input
                        type="file"
                        name="img02"
                        accept="image/*"
                        // onChange={(e) => setFormData({ ...formData, img02: e.target.files[0] })}
                        onChange={(e) => setImg02(e.target.files[0])}

                        className="w-full px-4 py-2 border rounded"
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Imagen Principal 3</label>
                    <input
                        type="file"
                        name="img03"
                        accept="image/*"
                        //  onChange={(e) => setFormData({ ...formData, img03: e.target.files[0] })}
                        onChange={(e) => setImg03(e.target.files[0])}

                        className="w-full px-4 py-2 border rounded"
                    />
                </div>

                {/* Botón de envío */}
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded hover:bg-blue-600"
                >
                    Agregar Propiedad
                </button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default AddProperties;
